import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const SponsorCard = (props) => {
  return (
    <div className="col-4">
      <div className="sponsors__card">
        <LazyLoadImage effect="blur" src={props.image} alt={props.imageAlt} />
        <h6>{props.title}</h6>
        <p>{props.text}</p>
        <a href={props.url} target="_blank" rel="noopener noreferrer"><button className="btn btn-bordered">Learn More</button></a>
      </div>
    </div>
  );
};

export default SponsorCard;
