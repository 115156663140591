import React from 'react';

import SponsorCard from './sponsorscard';

const SponsorsList = (props) => {
  return (
    <div className={`sponsors__list row`}>
      {props.sponsors.map((sponsor) => {
        return (
          <React.Fragment key={sponsor.id}>
            <SponsorCard image={sponsor.image} title={sponsor.title} text={sponsor.text} url={sponsor.url} imageAlt={sponsor.imageAlt} />
          </React.Fragment>
        );
      })}
    </div>
  );
};

export default SponsorsList;
